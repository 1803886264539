import {
  Box,
  Grid,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useCallback } from "react";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { useMutation } from "react-query";
import { PersonIcon } from "../../../../svgs/loan-application";
import { useStyles as useSectionStyles } from "../../../../utils/loan-application.styles";
import OptionsGroup from "../../components/OptionsGroup";
import Button from "../../components/Button";
import { useNotification } from "../../../../hooks/loan-application.hooks";
import { inviteUserToQuickLoan } from "../../../../api/scenario";

export const useStyles = makeStyles((theme) => ({
  optionsContainer: {
    width: "fit-content",
    gap: "16px",
    marginBottom: "24px",
  },
  inviteContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
    marginRight: "48px",
    [theme.breakpoints.down("xs")]: {
      marginRight: "0px",
      gap: "24px",
      width: "70%",
      marginBottom: "32px",
    },
  },
}));

const applicantOptions = [
  {
    value: 0,
    label: (
      <>
        Just Me
        <PersonIcon />
      </>
    ),
  },
  {
    value: 1,
    label: (
      <>
        Me + One
        <PersonIcon />
        <PersonIcon />
      </>
    ),
  },
  {
    value: 2,
    label: (
      <>
        Me + Two
        <PersonIcon />
        <PersonIcon />
        <PersonIcon />
      </>
    ),
  },
];
const NumberOfApplicantsStep = ({
  formik,
  handleNextStep,
  emailsSent,
  onEmailSent,
}) => {
  const classes = useStyles();
  const sectionClasses = useSectionStyles();
  const { showNotification } = useNotification();

  const useInviteToScenario = useMutation(
    ({ invitedEmails, scenarioId }) =>
      inviteUserToQuickLoan({ invitedEmails, scenarioId }),
    {
      onSuccess: (_, variables) => {
        variables.invitedEmails.forEach((email) => onEmailSent(email));
        showNotification("Invitation sent successfully", "success");
      },
      onError: () => {
        showNotification("Failed to send invitation", "error");
      },
    }
  );

  const handleSendInvitation = useCallback(
    (email) => {
      if (emailsSent.has(email)) {
        showNotification("Email already sent", "error");
        return;
      }
      useInviteToScenario.mutate({
        invitedEmails: [email],
        scenarioId: formik.values.scenarioId,
      });
    },
    [
      formik.values.scenarioId,
      useInviteToScenario,
      emailsSent,
      showNotification,
    ]
  );

  const handleChangeNumberOfApplicants = (newNumberOfCoApplicants) => {
    formik.setFieldValue("numberOfCoApplicants", newNumberOfCoApplicants);
  };

  const onNextStep = () => {
    const newCoApplicantsTouched = formik.values.coApplicants.map(
      (_, index) => index < formik.values.numberOfCoApplicants
    );
    formik.setFieldTouched("numberOfCoApplicants");
    formik.setFieldTouched("coApplicants", newCoApplicantsTouched);
    if (
      formik.values.coApplicants.some(
        (_, index) =>
          newCoApplicantsTouched.at(index) &&
          formik.errors.coApplicants?.at(index)
      )
    ) {
      return;
    }
    handleNextStep();
  };

  return (
    <Grid container>
      <OptionsGroup
        classes={classes.optionsContainer}
        selectedValue={formik.values.numberOfCoApplicants}
        onSelect={handleChangeNumberOfApplicants}
        options={applicantOptions}
        disabled={emailsSent.size > 0}
        // temporarily disable adding more applicants
        isOptionDisabled={(value) => value > 0}
      />
      <Grid item container xs={12}>
        {Array(formik.values.numberOfCoApplicants)
          .fill(null)
          .map((_, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box classes={{ root: classes.inviteContainer }} key={index}>
              <Typography classes={{ root: sectionClasses.optionGroupTitle }}>
                Invite to Application
              </Typography>
              <TextField
                name={`coApplicants[${index}]`}
                variant="outlined"
                label="Email *"
                placeholder="John.citizen@gmail.com"
                onChange={formik.handleChange}
                value={formik.values.coApplicants[index]}
                onBlur={formik.handleBlur}
                disabled={emailsSent.has(formik.values.coApplicants[index])}
                error={
                  formik.touched.coApplicants?.at(index) &&
                  Boolean(formik.errors.coApplicants?.at(index))
                }
                helperText={
                  formik.touched.coApplicants?.at(index) &&
                  formik.errors.coApplicants?.at(index)
                }
                FormHelperTextProps={{
                  style: { position: "absolute", bottom: "-20px" },
                }}
              />
              <Button
                disabled={emailsSent.has(formik.values.coApplicants[index])}
                endIcon={<NavigateNextIcon />}
                onClick={() =>
                  handleSendInvitation(formik.values.coApplicants[index])
                }
              >
                Send Invite
              </Button>
            </Box>
          ))}
      </Grid>
      <Grid
        container
        xs={12}
        md={6}
        justifyContent="space-between"
        classes={{ root: sectionClasses.navigationButtonsContainer }}
      >
        <Button
          disabled={emailsSent.size !== formik.values.numberOfCoApplicants}
          endIcon={<NavigateNextIcon />}
          onClick={onNextStep}
        >
          Next
        </Button>
      </Grid>
    </Grid>
  );
};

export default NumberOfApplicantsStep;
